import type { LoaderFunctionArgs } from "@remix-run/node";
import { redirect } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import styles from "./styles.css?url";
import IndexPage from "~/commons/components/IndexPage";

export const links = () => [{ rel: "stylesheet", href: styles }];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop")) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }

  const app = {
    title: "Maestro Themes Scheduler",
    description:
      "Schedule Sections and Blocks changes and theme publish with Maestro! Perfect for running sales and keeping your store fresh. Schedule changes to themes, sections, and blocks to match your sales periods. Keep your store updated with relevant graphics and content effortlessly.",
    image_url:
      "https://cdn.shopify.com/app-store/listing_images/d0379558a51d1acc51852963c848942b/icon/CI6Xr-KdpoYDEAE=.png",
    link: "https://apps.shopify.com/maestro-theme-scheduler"
  };

  const related_apps = [
    {
      title: "Smart Collections By Rankify",
      description:
        "Dynamic collection merchandising and sorting management by tags, prices, and product types.",
      image_url:
        "https://cdn.shopify.com/app-store/listing_images/d809aacde0790319c5c5edabfb74bb07/icon/CNynzd64nIcDEAE=.png",
      link: "https://apps.shopify.com/rankify-smart-collection-sort"
    },
    {
      title: "Prizify : Loyalty & Rewards",
      description:
        "Establish a lasting bond with your clientele by crafting your own loyalty and rewards initiative.",
      image_url:
        "https://cdn.shopify.com/app-store/listing_images/c184183a972fc18efe350eee1817e244/icon/CM_o-cGMnIYDEAE=.png",
      link: "https://apps.shopify.com/prizify-loyalty-rewards"
    },
    {
      title: "Quick Notice Clear Alert",
      description: "Clear alerts, smooth orders—keep customers in the loop!",
      image_url:
        "https://cdn.shopify.com/app-store/listing_images/e086f1c5a055cc1dabc27a58d413fa8a/icon/CMmZtZXit4YDEAE=.png",
      link: "https://apps.shopify.com/quick-notice-clear-alert"
    }
  ];

  return { related_apps, app };
};

export default function App() {
  const { related_apps, app } = useLoaderData<typeof loader>();

  return <IndexPage app={app} related_apps={related_apps} />;
}
