import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://fe15e61286547b914dbb0c6f166ced60@o86476.ingest.us.sentry.io/4507313369645056",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches
      }),
      Sentry.replayIntegration()
    ]
  });
}

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});
