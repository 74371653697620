export const initialState: { [key: string]: any } = {
  app: {
    delete: {
      id: null,
      name: ""
    }
  },
  collection: {
    title: "",
    description: "",
    product_limits: 250,
    product_strategies: [
      {
        condition_value: "last_7_days",
        id: 14,
        operator: "between",
        productStrategy_Id: 3,
        strategyType: "date"
      }
    ],
    is_active: true,
    exclude_products: [],
    pined_products: [],
    sort_strategy_id: 1,
    push_sold_out_to_buttom: false,
    job_status: "Pending",
    direction: "ASC",
    frequency: 720,
    products: {}
  },

  sort_strategies: [],
  product_strategies: [],
  frequencies: []
};
