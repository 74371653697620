export default function IndexPage({ app, related_apps }: Record<any, any>) {
  const { title, description, image_url, link } = app;

  const handleReadMore = () => open(link, "_blank");
  const handleInstall = (link: string) => open(link, "_blank");

  return (
    <>
      <main className="app-main">
        <div className="app-showcase">
          <div className="app-showcase__content">
            <h4 className="app-showcase__subtitle">About App</h4>
            <h1 className="app-showcase__title">{title}</h1>
            <p className="app-showcase__description">{description}</p>
            <div>
              <button className="app-showcase__button" onClick={handleReadMore}>
                Read More
              </button>
            </div>
          </div>
          <div className="app-showcase__image">
            <img src={image_url} alt={title} />
          </div>
        </div>
        <div className="related-app">
          <div className="related-app__header">
            <h4 className="related-app__title">Related Apps</h4>
            <p className="related-app__description">
              Find the perfect app for your needs. Get more done with Maestro
              Themes Schedules.
            </p>
          </div>
          <div className="related-apps-showcase">
            {related_apps.length > 0 ? (
              related_apps.map((app: any, index: number) => (
                <div key={index} className="related-app-showcase">
                  <div className="related-app-showcase__image">
                    <img src={app.image_url} alt="App Preview 1" />
                  </div>
                  <div className="app-showcase__content">
                    <h3 className="related-app-showcase__name">{app.title}</h3>
                    <p className="related-app-showcase__description">
                      {app.description}
                    </p>
                    <div>
                      <button
                        className="related-app-showcase__button"
                        onClick={() => handleInstall(app.link)}
                      >
                        Install
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Apps</p>
            )}
          </div>
        </div>
      </main>
      <footer>
        <p style={{ textAlign: "center" }}>
          Built with <span>❤</span>️ by
          <a href="https://xco.agency">
            <span>XCO Agency</span>
          </a>
          .
        </p>
      </footer>
    </>
  );
}
